@use "variables.scss" as *;
.Root {
  &__text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #222426;
    margin-bottom: 54px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}