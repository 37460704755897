@use "variables.scss" as *;
.Textarea__Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Textarea {
  font-family: Inter;
  font-style: normal;
  width: 100%;
  height: 200px;
  background: #fdfdfd;
  border-radius: 2px;
  border: 1px solid #94DFFF;
  box-shadow: 0px 0px 0px 2px #DDF2FE;
  transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -webkit-transition: 0.5s ease all;
  padding: 12px 2px 12px 16px;
  cursor: text;

  &_Input {
    width: 100%;
    height: 100%;
    resize: none;
    background: none;
    outline: none;
    border: none;
    position: relative;
    box-shadow: none;
    //@include placeholder-color(rgba(26, 26, 26, 0));

    font-weight: 500;
    font-size: 14px;
    color: #222426;
    line-height: 23px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(26, 26, 26, 0.1);
      border-radius: 100px;
    }
  }

  &:hover {
    border-color: #91c3fd;
  }

  &:focus {
    & + .Label_Default {
      //$size: 12px;
      font-size: 12px;
      left: 17px;
      top: 10px;
    }
  }

  & + .NoEmpty {
    //$size: 12px;
    font-size: 12px;
    left: 17px;
    top: 10px;
  }
}

.Focus {
  border-color: #61a6fa !important;

  .Textarea_Input {
    //@include placeholder-color(rgba(26, 26, 26, 0.7));
  }
}

//disabled style
.Textarea:disabled,
.Textarea:disabled:hover,
.Textarea:disabled:focus {
  cursor: not-allowed;
  pointer-events: none;
  background: #f4f4f5;
  border: 1px solid #e5e7eb !important;
  color: rgba(26, 26, 26, 0.5);
}

.Error {
  border: 1px solid #ef4343 !important;
}

.ErrorText {
  display: block;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #ef4343;
}

.Label {
  position: absolute;
  pointer-events: none;
  font-size: 16px;
  top: 28px;
  left: 17px;
  font-weight: 500;
  line-height: 100%;
  color: rgba(26, 26, 26, 0.5);
  transition: all 0.3s ease;
}

.ShowCount {
  position: absolute;
  right: 12px;
  bottom: 4px;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #333;

  &_CurrentValue {
    color: #FBBC05;
  }

  &_MaxValue {
    color: #FBBC05; //#94DFFF;
  }
}
