@use "variables.scss" as *;
.Root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1548px;
  flex-grow: 1;
  padding: 48px 0;

  @media (max-width: 1599px) {
    max-width: 1216px;
  }

  @media (max-width: 1279px) {
    max-width: 652px;
  }

  @media (max-width: 677px) {
    max-width: 350px;
  }

  @media (max-width: $mobile-width) {
    padding: 32px 0px 104px;
    max-width: 350px;
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 28px;
    font-size: 12px !important;
  }
}

.HeaderLogged {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 28px;
    font-size: 12px !important;
  }
}

.Title {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #9a41d1;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}

.SubTitleLogged {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 677px) {
    flex-direction: column;
  }
}

.EventsToggle {

  display: flex;
  flex-direction: row;

  & button {
    background: none;
    border: none;
    display: flex;
    width: 166px;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border-bottom: 1.5px solid #8A9299;
    color: #8A9299;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
}

.activeBtn {
  color: #222426 !important;
  border-bottom: 1.5px solid #FF7728 !important;
}

.CreateEventBtn {

  @media (max-width: 767px) {
    align-self: flex-end;
  }
  @media (max-width: 677px) {
    padding-top: 20px;
  }
}

.Actions {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 22px;
    justify-content: space-between;

    & > div > div > div > div {
      padding-left: 0 !important;
    }
  }
}

.CreateButton {
  margin-left: 12px;

  @media (max-width: 767px) {
    margin-left: 0;
  }

  & button {
    @media (max-width: 767px) {
      height: 36px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;;
    }
  }
}

.Plus {
  @media (max-width: 360px) {
    display: none;
  }
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Search {
  width: 192px;

  @media (max-width: 1279px) {
    display: none;
  }
}

.FilterButton {
  display: none;
  color: #00AFFA;
  font-size: 12px;
  line-height: 14px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding-left: 24px;
  background: url(../assets/filter.svg) no-repeat center left 4px;

  @media (max-width: 1280px) {
    display: flex;
  }
}

.FilterMenu {
  display: none;
  width: 100vw;
  height: 100vh;

  @media (max-width: 1280px) {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    right: -100vw;
    z-index: 9999;
    background: rgba(69, 73, 76, 0.6);
    overflow-y: auto;
  }
}

.FilterMenuOpen {
  composes: FilterMenu;
  right: 0;
}

.MenuSearch {
  width: 100%;
  padding: 20px;
  max-width: 300px;
  height: max-content;
  min-height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 1px rgba(65, 54, 32, 0.02), 0px 6px 24px rgba(65, 54, 32, 0.05), 0px 6px 10px rgba(65, 54, 32, 0.1);
  border-radius: 4px 0px 0px 4px;

  @media (max-width: $mobile-width) {
    max-width: inherit;
  }
}

.Close {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 36px;
  height: 36px;
  background: url(../assets/close.svg) no-repeat center;
  cursor: pointer;
}

.BackButton {
  margin-right: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.BackIcon {
  width: 16px;
  height: 16px;
  margin: 4px;
  background: url(../assets/back.svg) no-repeat center;
}

.HeaderActions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $tablet-width) {
    width: 100%;
    margin-top: 22px;
  }
}

.FavoriteButton {
  margin-left: 28px;
  background: #FFCA2A;
}

.StarIcon {
  width: 20px;
  height: 20px;
  background: url(../assets/star-button-icon.svg) center no-repeat;
}

.EventButton {
  position: relative;

  &__label {
    &_beta {
      position: absolute;
      top: -13px;
      right: -13px;
      z-index: 1;

      display: inline-flex;
      padding: 6px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 31px;
      background: #FFF;
      box-shadow: 0px 3px 9px 0px rgba(65, 54, 32, 0.10), 0px 0px 1px 0px rgba(128, 128, 128, 0.15);
    }

    &_lock {
      position: absolute;
      top: 9px;
      right: -13px;
      z-index: 1;

      display: inline-flex;
      padding: 6px;
      align-items: flex-start;
      border-radius: 31px;
      background: #FFF;
      box-shadow: 0px 3px 9px 0px rgba(65, 54, 32, 0.10), 0px 0px 1px 0px rgba(128, 128, 128, 0.15);
    }
  }

  &__text {
    &_beta {
      color: #9A41D1;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.ProModalWrapper {
  position: fixed;
  width: 100vw;
  height: auto;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  z-index: 10000;
  bottom: 72px;

  @include iftablet {
    height: 100vh;
    flex-direction: column;
    align-items: center;
    bottom: unset;
  }

  &__open {
    display: flex;
  }
}

.ProModal {
  position: relative;
  overflow: auto;
  max-height: 90vh;
  box-shadow: 0px 2px 1px rgba(65, 54, 32, 0.02), 0px 6px 24px rgba(65, 54, 32, 0.05), 0px 6px 10px rgba(65, 54, 32, 0.1);
  border-radius: 4px;
  color: #1a1a1a;
  overflow-x: hidden;
  width: 100%;
  max-width: 480px;
  background: url(/img/become-pro-modal-bg-mobile.jpg) center center/cover no-repeat;
  padding: 32px 10px;

  @include iftablet {
    max-width: 733px;
    background: url(/img/become-pro-modal-bg.jpg) center center / cover no-repeat;
    padding: 32px;
  }

  &__close {
    position: absolute;
    top: 38px;
    right: 10px;
    cursor: pointer;
    @include iftablet {
      top: 16px;
      right: 32px;
    }
  }

  &__title {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #222426;
    margin: 0;
    padding-bottom: 16px;
    @include iftablet {
      padding-bottom: 20px;
    }
  }

  &__text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    color: #222426;
    max-width: 310px;
    padding-bottom: 176px;

    @include iftablet {
      padding-bottom: 77px;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    @include iftablet {
      width: fit-content;
    }
  }
}

.Pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include iftablet {
    justify-content: flex-end;
  }
}