@use "variables.scss" as *;
.Root {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  background: $white-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.RootFinished {
  composes: Root;
}

.Image {
  position: relative;
  width: 100%;
  height: 170px;
  background-size: cover;
  background-position: center;
  border-radius: 2px 2px 0 0;
  font-size: 12px;
  line-height: 14px;
  color: $white-color;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    z-index: 1;
  }
}

.Cloud {
  position: absolute;
  display: flex;
  align-items: center;
  height: 22px;
  background: rgba(18, 25, 33, 0.7);
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  z-index: 3;
}

.Status {
  composes: Cloud;

  color: $black;
  background: #e6f99f !important;
  bottom: 12px;
  right: 12px;
}

.StatusModerating {
  composes: Cloud;

  color: $black;
  background: #ffe699 !important;
  bottom: 12px;
  right: 12px;
}

.StatusDeclined {
  composes: Cloud;

  color: $black;
  background: #ffbdbd !important;
  bottom: 12px;
  right: 12px;
}

.StatusDraft {
  composes: Cloud;

  color: $black;
  background: #d9f2ff !important;
  bottom: 12px;
  right: 12px;
}

.Type {
  composes: Cloud;

  bottom: 12px;
  left: 12px;
  z-index: 3;
}

.Main {
  padding: 16px 20px 24px;
  border: 1px solid $grey-4;
  border-top: none;
  border-radius: 0 0 4px 4px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Spot {
  font-size: 12px;
  line-height: 14px;
}

.Name {
  margin-bottom: 10px;
  color: $black;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  flex-grow: 1;
}
.Tags {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  .Tag {
    padding: 8px;
    border-radius: 4px;
    background: $blue-light;
    color: $black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}
.Description {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Grey {
  color: $grey-1;
}

.AdditionalInfo {
  margin-top: 12px;
  color: $grey-1;
  font-size: 12px;
  line-height: 16px;
  padding-left: 26px;
}

.Location {
  composes: AdditionalInfo;
  background: url(./point.svg) no-repeat left;
}

.Date {
  //background: url(./calendar.svg) no-repeat left;
  color: $black;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  & svg {
    margin-right: 8px;
  }
}

.People {
  composes: AdditionalInfo;
  background: url(./people.svg) no-repeat left;
}

.Favorite {
  position: absolute;
  bottom: 0;
  right: -1px;
  width: 48px;
  height: 48px;
  background: url(../../assets/favorite.svg) no-repeat center;
  z-index: 1;
}
