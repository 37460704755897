@use "variables.scss" as *;
.EventApplication {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-style: normal;

  &__header  {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8A9299;
    margin-bottom: 8px;
  }

  &__brand {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    &__image {
      width: 40px;
      height: 40px;
      min-width: 40px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding-right: 0;
      }
    }

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #222426;
    }
  }

  &__offer{
    font-weight: 500;
    font-size: 14px;
    color: #222426;
    line-height: 16px;
    margin-top: 24px;
    margin-bottom: 6px;
  }

  &__select{
  }

  &__editor {
    padding: 10px 0 32px;
    //margin-top: 32px; // if Package select on
    margin-top: 22px; // if Package select off
  }

  &__message {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #222426;
    padding: 0 0 12px;
    //margin-top: 32px; // if Package select on
    margin-top: 22px; // if Package select off
  }

  &__edit {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00AFFA;
    gap: 8px;
    padding: 10px 0 42px;

    & svg, p {
      cursor: pointer;
    }
  }

  &__btns_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }
}