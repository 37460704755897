@use "variables.scss" as *;
.IncomeApplication {
  display: flex;
  flex-direction: column;

  font-family: Inter;
  font-style: normal;

  & h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8A9299;
    margin: 0;
    padding: 0 0 10px;
  }

  &__brand {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 24px;
    gap: 8px;

    &__image {
      width: 40px;
      height: 40px;
      min-width: 40px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding-right: 0;
      }
    }

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #222426;
    }
  }

  &__message {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #222426;
    padding: 0 0 32px;
  }

  &__report {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: -20px;
    padding: 10px 0 10px;

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FF6969;
      padding-left: 8px;
      cursor: pointer;
    }
  }

  &__report__bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    padding: 10px 0 10px;

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FF6969;
      cursor: pointer;
    }
  }

  &__report__btn {
    text-decoration: underline;
    text-decoration-color: #FF6969;
    text-underline-offset: 2px;

    &:hover {
      color: #fa4646;
      text-decoration-color: #fa4646;
    }
  }

  &__accept {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-top: 22px;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 24px;
    background: rgba(61, 215, 0, 0.10);

    @include iftablet {
      padding: 24px 32px;
    }

    & h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #222426;
      margin: 0;
      padding-bottom: 8px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;

      @include iftablet {
        flex-direction: row;
        gap: 24px;
        align-items: center;
      }
    }

    &_row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding-left: 8px;
      cursor: pointer;
    }
  }

  &__footer_wrapper {
    position: relative;
    height: 68px;
    margin-top: 29px;
  }

  &__footer {
    position: absolute;
    left: -32px;
    top: 0;
    z-index: 1000;
    width: 600px;
    background-color: #F8F8F8;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 20px 32px 12px;

    @include iftablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 32px 32px;
    }

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }

    & button {
      width: 82px;
    }
  }

  &__deal {
    position: absolute;
    left: -22px;
    top: 0;
    z-index: 1000;
    width: 600px;
    background-color: #F8F8F8;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    //padding: 18px 32px 22px;
    padding: 23px 32px 17px;

    @include iftablet {
      //padding: 18px 32px 22px;
      padding: 23px 32px 17px;
      left: -32px;
    }

    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #222426;
    }
  }
}

.IncomeApplicationHeader {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 20px;
  margin-right: 30px;

  & > div {
    display: flex;
    align-items: center;
  }
}