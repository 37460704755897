@use "variables.scss" as *;
.Empty {
  color: #8A9299;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.NoEventsText {
  margin-top: 18px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $black;
}

.NoEventsDescription {
  margin-top: 14px;
  font-size: 14px;
  line-height: 20px;
}

.EmptyButton {
  margin-top: 20px;
}

.Grid {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 320px);

  @media (max-width: 1599px) {
    grid-template-columns: repeat(3, 320px);
  }

  @media (max-width: 1279px) {
    grid-template-columns: repeat(2, 320px);
  }

  @media (max-width: 677px) {
    grid-template-columns: 350px;
  }
}