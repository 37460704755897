$primary-color: #9a41d1;
$primary-pressed: #bd84e1;
$primary-hover: #7528a4;

$primary-2-color: #ff7728;
$primary-2-pressed: #ff7e33;
$primary-2-hover: #ff5e00;

$primary-3-color: #ffca2a;
$primary-3-pressed: #ffcc33;
$primary-3-hover: #f5b800;

$secondary-color: #e8f0f7;
$secondary-pressed: #f0f5fa;
$secondary-hover: #d5e4f1;

$secondary-2-color: #f4eafa;
$secondary-2-pressed: #e9d6f5;
$secondary-2-hover: #f6effb;

$tertiary-color: #00affa;
$tertiary-pressed: #3dc5ff;
$tertiary-hover: #00a4eb;

$destructive-color: #ff6969;
$destructive-pressed: #ff3838;
$destructive-hover: #ff8080;

$icon-color: #fff2cc;
$icon-pressed: #ffca2a;
$icon-hover: #ffe69e;

$additional-color: #ff7728;
$additional-pressed: #ff7e33;
$additional-hover: #ff5e00;
$additional-2-color: #ffdfcc;

$blue-main-color: #00affa;
$blue-main-focused: #94dfff;
$blue-main-light-color: #f0faff;
$blue-main-dark-color: #00a4eb;
$blue-main-pressed: #3dc5ff;

$orange-color: #ff7628;
$orange-hover: #ff5e00;
$orange-pressed: #ff7e33;

$orange-2-color: #ffefe5;
$orange-2-hover: #ffe6d5;
$orange-2-pressed: #ffdfcc;

$white-color: #ffffff;
$main-background: #ffffff;

$coral-dark: #e86b73;
$coral: #f47c83;
$coral-light: #ffa8ae;
$bg-milk: #faeee7;

$creme: #f9eee8;

$blue-dark: #0069cc;
$blue-main: #1782e5;
$blue-pressed: #3dc5ff;
$blue-focus: #96ccff;
$blue-light: #f1faff;
$blue-tab: #1782e6;

$black: #222426;
$black-light: #45494c;
$grey-0: #6c7278;
$grey-1: #8a9299;
$grey-2: #b6bbbf;
$grey-3: #d8d9da;
$grey-4: #e3e4e5;
$grey-5: #f5f6f7;

$success-border: #27c486;
$green-light: #c2f2e1;
$bg-green: #ebfaf4;
$green-2: #87e0c0;

$error-border: #ff6969;

$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-width: 1440px;
$width-1200: 1200px;
$width-1280: 1280px;
$width-1920: 1920px;

$z-list: 10000; // TODO было 10
$z-tooltip: 100;
$z-dialog: 10000;

@mixin iftablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin ifdesktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin width-1200 {
  @media (min-width: $width-1200) {
    @content;
  }
}

@mixin width-1280 {
  @media (min-width: $width-1280) {
    @content;
  }
}

@mixin iflarge {
  @media (min-width: $large-width) {
    @content;
  }
}

@mixin width-1920 {
  @media (min-width: $width-1920) {
    @content;
  }
}

@function zoom($size, $width) {
  @return ($size / $width) * 100vw;
}